<template>
  <div class='compliance-ethics'>
    <div class="compliance-ethics__content">
      <div class="compliance-ethics__descr">
        <h2 class="title">{{$t('complianceEthics')}}</h2>
        <p>{{$t('complianceEthicsContent')}}</p>
        <a href="#">{{$t('complianceEthicsDocument')}}</a>
      </div>
      <div class="compliance-ethics__img">
        <img src="@/assets/img/compliance/ethic.jpg" alt="image">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ComplianceEthic'
  }
</script>